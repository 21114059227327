<template lang="pug">
  FormModal(title="New Image", endpoint="blocks", :data="data", v-bind="$attrs", @success="onSuccess")
    component(:is="tabsComponent")
      component(:is="tabComponent", title="Image")
        InputGroup(label="Image", name="featured_image", description="JPG, PNG, GIF or WEBP. Up to 10 MB.")
          ImageInput(v-model="data.featured_image", prepend-icon="block-image")
        InputGroup(label="URL - Optional", name="content_url")
          TextInput(v-model="data.content_url", prepend-icon="link")
        ImageDisplayInput(v-model="data.image_display")
        InputGroup(v-if="!collection_id", name="collection_ids", label="Collection(s) - optional", description="Click the chevron or your down arrow key to see list")
          MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="options/all/collections", option-label="label")
          
      Tab(v-if="collection_id", title="Inspires")
        InputGroup(label="Customisable - Optional", name="inspires_customisable")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_customisable")
            span.ml-4 Allow advisors to customise this block's content
        //- InputGroup(label="Placeholder - Optional", name="inspires_placeholder")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_placeholder")
            span.ml-4 Only display this block if the advisor customises it
        InputGroup(label="Downloadable - Optional", name="inspires_downloadable")
          div.mt-2.inline-flex.items-center
            ToggleSwitch.rounded-full(v-model="data.inspires_downloadable")
            span.ml-4 Display a button to download the image
</template>

<script>
import { Tabs, Tab } from "@syntax51/app-kit"
import { mapGetters } from "vuex"
import ImageDisplayInput from "../../../components/Form/ImageDisplayInput.vue"

export default {
  components: {
    ImageDisplayInput,
    Tabs,
    Tab
  },
  props: {
    collection_id: {
      type: Number
    }
  },
  data: () => ({
    data: {
      collection_id: null,
      collection_ids: [],
      block_type: "image",
      featured_image: "",
      image_display: "cover",
      content_url: "",
      inspires_customisable: false,
      inspires_placeholder: false,
      inspires_downloadable: false
    },
    tabsComponent: null,
    tabComponent: null
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  created() {
    this.data.collection_id = this.collection_id
    this.tabsComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tabs : "div"
    this.tabComponent = this.user.partner.inspires_customisations_enabled && this.collection_id ? Tab : "div"
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Image successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
